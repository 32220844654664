// This is used to create the invoice of a user's order.  It takes in their
// order and creates an invoice that can be downloaded as a word doc.
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";

// This function calculates the total for the user's order including all their fees.
function UserGrandTotal(
  foodList,
  cashPortion,
  customerContribution,
  donationSubsidy,
  deliveryFee,
) {
  // A dictionary of the the farms(keys) and the total owed to them (values)
  const farmTotals = PriceTotals(foodList, true);
  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(foodList);
  // The total including the donation or subsidy
  let grandTotal = 0;
  // The total volunteer Fee Cash
  const volunteerFeeCash = cashPortion;

  // Sum up the grandTotal of all the farms together.
  Object.keys(farmTotals).forEach((farmTotal) => {
    grandTotal += farmTotals[farmTotal];
  });

  // Cycle through the package Totals and add them to the grandTotal.
  Object.keys(packageTotals).forEach((packageTotal) => {
    grandTotal += packageTotals[packageTotal];
  });

  // Add in the subdidy and donation amounts
  if (customerContribution === "donation") {
    grandTotal += donationSubsidy;
  } else {
    grandTotal -= donationSubsidy;
  }

  // If the volunteer cash portion is greater than 0 then we need to add to the
  // grandTotal.
  if (parseFloat(volunteerFeeCash) > 0) {
    grandTotal += parseFloat(volunteerFeeCash);
  }

  // If the delivery fee is greater than 0 then add it to the grandTotal.
  if (parseFloat(deliveryFee) > 0) {
    grandTotal += deliveryFee;
  }

  // Make sure that the grandTotal isn't ever negative.
  if (grandTotal < 0) {
    grandTotal = 0;
  }
  return grandTotal;
}

// This function takes customer's order and generates an invoice.
export default function CreateDownloadOrderInvoice(
  selectedDate,
  deliveryFeeTotal,
  customerContribution,
  volunteerFeeCredits,
  volunteerFeeCash,
  foodList,
  donationSubsidy,
  userInfo,
  invoiceNumber,
) {
  // The title of the doc which is the distribution location and the date in list format.
  const userFullName = `${userInfo.firstName} ${userInfo.lastName}`;

  // The header of the invoice.
  const invoiceHeader = `
    <div style="position: relative; width: 100%;">
      
    </div>

    <table style="width: 100%; font-size: 12px; border-collapse: collapse; border: none;">
      <tr>
        <!-- Center the header -->
        <td style="text-align: center; vertical-align: middle; width: 100%; border: none;">
          <h1 style="margin: 0;">INVOICE</h1>
        </td>
        <!-- Logo on the right -->
        <td style="text-align: right; vertical-align: middle; width: 20%; border: none;">
          <img
            src="https://lh3.googleusercontent.com/pw/AM-JKLXj1A0JBCwtz49sMvteUQlZx1BiHQ0SbiWHgm230f6lHICeuSpJ6gI0g-O4IgT18j8FqooOuapK7vfXJeceR7pqZjO2eKLQzLfP_ax-yvA2Ui5WdR55vtbTshNIF12XbAqbGkWpLgLGA3o7vXi7zQO-=s789-no?authuser=1"
            width="40" height="40" />
        </td>
      </tr>
    </table>

    <table style="width: 100%; font-size: 12px; border-collapse: collapse; border: none;">
      <tr>
        <td style="text-align: left; vertical-align: top; width: 50%; border: none;">
          <p style="font-size: 15px;">
            Jewish Family Services<br/>
            Farm-to-Plate Marketplace
          </p>
          <p style="font-size: 12px;">
            <b>Phone :</b> (604) 257-5151 <br/>
            <b>Email :</b> farmtoplate@jfsvancouver.ca <br/>
            <b>Address :</b> 54 E 3rd Ave. Vancouver, BC V5T 1C3 <br/>
          </p>
        </td>
        <td style="text-align: right; vertical-align: top; width: 50%; border: none;">
          <p style="margin: 0;">
            <b>Invoice #:</b> ${invoiceNumber}<br/>
            <b>Date:</b> ${selectedDate}<br/>
          </p>
        </td>
      </tr>
    </table>

    <table style="width: 100%; font-size: 12px; border-collapse: collapse; border: none;">
      <tr>
        <td style="text-align: left; vertical-align: top; width: 50%; border: none;">
          <p style="margin: 0;">
            <b>To:</b><br/>
            ${userFullName}<br/>
            <b>Phone:</b> ${userInfo.phoneNumber}<br/>
            <b>Email:</b> ${userInfo.email}<br/>
          </p>
        </td>
        <td style="text-align: right; vertical-align: top; width: 50; border: none;">
          <p style="margin: 0;">
            <b>For:</b><br/>
            Food order on ${selectedDate}<br/>
          </p>
        </td>
      </tr>
    </table>
  `;

  // The body with the food items listed.
  const invoiceBody = `
    <table style="width:100%; border-collapse: collapse; font-size: 12px;">
      <thead>
        <tr style="background-color: #f2f2f2;">
          <th style="border: 1px solid #ddd; padding: 8px;">Item</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Quantity</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Farm</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Price per Unit</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Total Price</th>
        </tr>
      </thead>
      <tbody>
        ${foodList
          .map((food) => {
            const individualPrice = parseFloat(
              food.price /
                (food.distributionQuantity / food.individualQuantity),
            ).toFixed(2);
            const totalPrice = (individualPrice * food.quantity).toFixed(2);
            return `
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">${food.item}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${food.quantity}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${food.farmName}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">$${individualPrice}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">$${totalPrice}</td>
              </tr>
            `;
          })
          .join("")}
      </tbody>
    </table>
  `;

  // Calculate the user's order total.
  const grandTotal = UserGrandTotal(
    foodList,
    volunteerFeeCash,
    customerContribution,
    donationSubsidy,
    deliveryFeeTotal,
  ).toFixed(2);

  // The final count and all the fees and the payment method.
  const invoiceFooter = `
    <p style="font-size: 12px; text-align:right;">
      <b>Delivery Fee:      </b> $${grandTotal} <br/>
      <b>Participation Fee Credits Used:      </b> $${grandTotal} <br/>
      <b>Participation Fee Cash:</b> ${volunteerFeeCredits} credits <br/>
      <b> ${customerContribution}:      </b> $${donationSubsidy.toFixed(
        2,
      )} <br/>
       
    </p>
    <p style="font-size: 14px; text-align:right;">
        <b>Total:</b> $${grandTotal}
    </p>

    <p style="text-align:center; font-size: 12px; "> 
      Please make all payments via etransfer by sending to farmtoplate@jfsvancouver.ca upon receiving food.<br/>
      Thank you for your support!
    </p>
  `;

  // Puts everything into one html message.
  const htmlContent = `
    <html>
      <head>
        <title>Invoice</title>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
        ${invoiceHeader}
        ${invoiceBody}
        ${invoiceFooter}
      </body>
    </html>
  `;

  const preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title><style>.order-container {border: 1px solid #000;padding: 10px;margin-bottom: 20px;width: calc(50% - 10px);box-sizing: border-box;}.order-container p, .order-container ul {margin: 0;padding: 0;}</style></head><body>";
  const postHtml = "</body></html>";

  // Adds everything together to be ready for the html.
  const html = preHtml + htmlContent + postHtml;

  // Specify link url
  const url =
    "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  const filename = `F2PM Invoice ${selectedDate}-${invoiceNumber}.doc`;

  // Create download link downloadString
  const downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = url;

  // Setting the file name
  downloadLink.download = filename;

  // triggering the function
  downloadLink.click();

  document.body.removeChild(downloadLink);

  // Return.
  return 0;
}
