// Renders the list of food items that the distribution location ordered.  Takes
// in the food list and renders it to be viewable by the user.
import React, { useState, useEffect } from "react";
import { AuthContext } from "../authentication/Auth.js";
import MyAccountFoodRow from "../../pages/MyAccountPage/Components/MyAccountFoodRow.jsx";
import PantryMyAccountFoodRow from "../../pages/MyAccountPage/Components/PantryMyAccountFoodRow.jsx";
import AddItemToPantryOrderDialog from "../../pages/MyAccountPage/Dialogs/AddItemToPantryOrderDialog.jsx";
import SortFilter from "../../pages/MyAccountPage/Components/SortFilter.jsx";

export default function Orders({
  order,
  foodList,
  editing,
  orderLog,
  updateOrderLog,
  orderIndex,
  publicView,
  multipleSelection,
  selectedItems,
  setSelectedItems,
  selectedPantryItems,
  setSelectedPantryItems,
  pantryFoodItems,
}) {
  const { userInfo } = React.useContext(AuthContext);
  // This is used to hold the foodItems so that it can be sorted on
  // this page.
  const [foodListState, setFoodListState] = useState([...foodList]);
  // This is used to hold the pantry food items so that it can be sorted.
  const [pantryFoodItemsState, setPantryFoodItemsState] = useState([
    ...pantryFoodItems,
  ]);

  useEffect(() => {
    setFoodListState([...foodList]);
    setPantryFoodItemsState([...pantryFoodItems]);
  }, [foodList, pantryFoodItems]);

  // If the user selects a foodItem or a pantry item.
  const handleSelectionChange = (e, index, pantry) => {
    const target = e.target;
    // If pantry is set to true we know th user needs to update the selected pantry
    // items.
    if (pantry) {
      // Update whether this pantry foodItem is checked or not at the given index of the foodItem
      // list.
      setSelectedPantryItems({
        ...selectedPantryItems,
        [index]: target.checked,
      });
    } else {
      // Update whether this foodItem is checked or not at the given index of the foodItem
      // list.
      setSelectedItems({ ...selectedItems, [index]: target.checked });
    }
  };

  return (
    <>
      {publicView && (
        <SortFilter
          foodList={foodListState}
          setFoodList={setFoodListState}
          originalFoodList={[...foodList]}
          pantryFoodItems={pantryFoodItemsState}
          setPantryFoodItems={setPantryFoodItemsState}
          originalPantryFoodItems={[...pantryFoodItems]}
          noSortByOrdered={true}
        />
      )}
      {foodListState.map((food, idx) => (
        <MyAccountFoodRow
          key={food + idx}
          food={food}
          editing={editing}
          multipleSelection={multipleSelection}
          idx={idx}
          communityFoodRemove={() => console.log("REMOVE")}
          userType={userInfo.userType}
          hideButtons={true}
          userInfo={userInfo}
          order={order}
          orderLog={orderLog}
          updateOrderLog={updateOrderLog}
          orderIndex={orderIndex}
          handleSelectionChange={handleSelectionChange}
          publicView={publicView}
        />
      ))}
      {pantryFoodItems.length !== 0 ? (
        <>
          <h5 className="Add-Items-Header">Items Taken From the Pantry</h5>
          {editing && (
            <AddItemToPantryOrderDialog
              order={order}
              orderLog={orderLog}
              updateOrderLog={updateOrderLog}
              orderIndex={orderIndex}
              userInfo={userInfo}
            />
          )}
          {pantryFoodItemsState.map((food, idx) => (
            <PantryMyAccountFoodRow
              key={food + idx}
              food={food}
              editing={editing}
              multipleSelection={multipleSelection}
              idx={idx}
              communityFoodRemove={() => console.log("REMOVE")}
              userType={userInfo.userType}
              hideButtons={true}
              userInfo={userInfo}
              order={order}
              orderLog={orderLog}
              updateOrderLog={updateOrderLog}
              orderIndex={orderIndex}
              handleSelectionChange={handleSelectionChange}
            />
          ))}
        </>
      ) : (
        <>
          {editing && (
            <>
              <h5 className="Add-Items-Header">Items Taken From the Pantry</h5>
              <AddItemToPantryOrderDialog
                order={order}
                orderLog={orderLog}
                updateOrderLog={updateOrderLog}
                orderIndex={orderIndex}
                userInfo={userInfo}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
