// This is used to display the row on the myaccount page of a foodItem that was taken
// from the pantry items.
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import PantryFoodItemPriceChangeDialog from "../Dialogs/PantryFoodItemPriceChangeDialog.jsx";
import EditDistributionLocationItemForm from "./EditDistributionLocationItemForm.jsx";
import { getIconByKey } from "../../../images/Icons"; // Import the function to get icons

export default function PantryMyAccountFoodRow({
  idx,
  food,
  editing,
  multipleSelection,
  userInfo,
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  handleSelectionChange,
}) {
  // The individual description of the food item for the user to know how
  // much they are purchasing.
  let individualDescription = food.individualDescription;
  // Set the price of the individal item
  let individualPrice = parseFloat(
    food.price / (food.distributionQuantity / food.individualQuantity),
  );

  // if suggestedPrice is true then we want to set the price to 0
  if (food.suggestedPrice) {
    // First add to the description the suggested Donation.
    individualDescription = individualDescription.concat(
      " - Suggested Donation of $",
      individualPrice,
    );
    // Then reset the prices to 0.
    individualPrice = 0.0;
  }

  // The default image to display if this element has no image.
  let displayImage =
    "https://lh3.googleusercontent.com/Issibzc2aRSgeCb-ZEvy2FdRek0t_76tclQJR7sZ64UFX2BHJPi3bXlBGpC_CAMDlwvb9jx_UPanf5jf0lKhXqd2qUqs1JvrEnRohV8Rv0-Qc84mgzMPe4FRYRvAuoO-5oj5gG1cps6KS7pTPh-hVJHjUCFmoUJt_mP1XYf-7ToSp16li0EoxMrTf6XOcRXq4t1D4QNOsy0HFR67bPCz_aFLzAnGGRBn8fYeg6ZDBrEA_bmJT574DSGQ9KVPE8xbJIGsbAXg7gkak6w-kUwR9Zh43UoE_R7fMeH7N3Ck2zGUPz8fk2gqHT_PlzQ_8gSDlmvNj8XJexH_PofD_fvBF3qfJW5pDO124JmKKlgdbNzXLM0VUpkoUH95mOVkblwpQzG4LJ-GXctSk-ycY1ikWj_xC_R1uyz8drfdXkqtIC0lyWhNwtVzqlZmHjTsOBVsiyaeqvtmFDZetEvuXm8vYTI4bIAa89eXH1Zt81g9XKVfubR8_eQH-3DNAC3Jam84_ro_38rZwxqI--HcJzgj1zcjV6FLCyFeegovwtSxC3VX70XZFUH4Qlv7bBfRK4CCs6lg-AlNgIYmnAr2Jjik9oL2y567f4zK_VROoVH7994c2QJ4S0QhX3-KntKrgIaxJxdrDk-6dayyMprKCI-ZXfF7uT3qk1oDR1HVBkW1G_Y60jyZvWL-dtoS-m1rzr5DrRTtYpxcdKVIMWuHbbGAGlQ=s512-no?authuser=2";

  // If the foodItem does have an image to display it will display it instead.
  if (!(food.displayImage === undefined || food.displayImage === "")) {
    displayImage = food.displayImage;
  }

  return (
    <div key={idx} className="Editing-Remove-Food-Item">
      <div
        style={{
          display: "flex",
          background: "rgb(114 255 113)",
          width: "100%",
          marginBottom: "5px",
        }}
      >
        <img
          style={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            margin: "5px",
          }}
          alt="Food"
          src={displayImage}
          referrerPolicy="no-referrer"
        />
        <div key={food + idx} className="Pantry-Food-Item">
          <div className="Food-Item-Desc-Quantity">
            <div className="Food-Item-Tags-And-Name">
              <h5 className="Food-Item-Child Food-Item-Header">
                {food.item}, {individualDescription}
              </h5>
              {/* Check if food.tagList exists and has items */}
              {food.tagList && food.tagList.length > 0 && (
                <div style={{ display: "inline-flex", marginLeft: "8px" }}>
                  {food.tagList.map((tag) => (
                    <span key={tag.key} style={{ marginRight: "4px" }}>
                      {getIconByKey(tag.key)}{" "}
                      {/* Render the icon for each tag */}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <h5 className="Food-Item-Child Food-Item-Header">
              x{food.quantity}
            </h5>
          </div>
          <div className="Order-Bottom-Row">
            <p className="Food-Item-Child">{food.farmName}</p>
            <span>
              <span className="Editing-Price-Change">
                {editing && !multipleSelection && (
                  <PantryFoodItemPriceChangeDialog
                    userInfo={userInfo}
                    order={order}
                    foodItemUpdate={food}
                    orderLog={orderLog}
                    updateOrderLog={updateOrderLog}
                    orderIndex={orderIndex}
                  />
                )}

                <p className="Food-Item-Child">
                  {" "}
                  ${individualPrice.toFixed(2)}{" "}
                </p>
              </span>
            </span>
          </div>
        </div>
        {editing && multipleSelection && (
          <Checkbox
            name="foodItem"
            onChange={(e) => handleSelectionChange(e, idx, true)}
          />
        )}
        {editing && !multipleSelection && (
          <EditDistributionLocationItemForm
            foodItem={food}
            order={order}
            foodItemUpdate={food}
            orderLog={orderLog}
            updateOrderLog={updateOrderLog}
            orderIndex={orderIndex}
            userInfo={userInfo}
            limit={food.limit}
            index={idx}
          />
        )}
      </div>
    </div>
  );
}
