// Used by MyAccount to display the user's order history.
import React, { useState, useEffect } from "react";
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";
import OrderDetailsTextMyAccount from "../../../components/OrderDetails/OrderDetailsTextMyAccount";
import Orders from "../../../components/OrderDetails/Orders";
import { Grid } from "@material-ui/core";
import ConfirmOrderDialog from "../../../components/dialogs/ConfirmOrder.js";
import CalculateCommunityLocationStatus from "../../../functions/CalculateCommunityLocationStatus.js";
import LoadingContent from "../../../components/LoadingContent.jsx";
import Button from "@material-ui/core/Button";
import CreateDownloadOrderInvoice from "../../../components/dialogs/functions/CreateDownloadOrderInvoice.js";

// Creates the entire order object. The date, status, list of foods and the button
// to order again or cancel their order
function Order(props) {
  // This sets the current status of a community host.
  const [currentStatus, setCurrentStatus] = useState([
    "loading",
    "loading",
    false,
  ]);
  const order = { ...props.order };
  // A dictionary of the the farms(keys) and the total owed to them (values)
  const [farmTotals, setFarmTotals] = useState({});
  // The totals taken from the pantry
  const [packageTotals, setPackageTotals] = useState({});
  // The total including the donation or subsidy
  const [grandTotal, setGrandTotal] = useState(0);
  // The total packaging fee.
  const [packageFeeTotal, setPackageFeeTotal] = useState(0);
  // The total delivery fee.
  const [deliveryFeeTotal, setDeliveryFeeTotal] = useState(0);
  // The donation subsidy the user is using
  const [donationSubsidy, setDonationSubsidy] = useState(order.donationSubsidy);
  // If it's an individual they'll have selected a pickupLocation
  const pickupLocation = order.pickupLocation;
  let communityPickup = "";
  // First check if communityPickup is defined as in older orders it won't be.
  if (order.communityPickup) {
    // check to see if the communityPickup was used or not.
    if (order.communityPickup.length !== 0) {
      communityPickup = order.communityPickup;
    }
  }
  // This is the year the order was placed YYYY
  const year = order.orderDate.substring(0, 4);
  // This is the month the order was placed MM
  const month = order.orderDate.substring(4, 6);
  // This is the day the order was placed DD
  const day = order.orderDate.substring(6, 8);
  // The unique invoice number that is the current date and the last three digits of the time.
  const invoiceNumber =
    order.orderDate.substring(0, 8) +
    order.orderDate.substring(order.orderDate.length - 3);
  const userInfo = props.userInfo;
  // The list of food that the user ordered
  const foodList = order.foodList;
  // The total volunteer Fee Cash
  const volunteerFeeCash = order.cashPortion;
  // The total volunteer Fee Credits
  const volunteerFeeCredits = order.creditPortion;
  // Grid stuff
  let rightColumnWidth = 2;
  // The farmer contact methods
  const contactMethodsStrings = [];
  // Prints out how each farmer was contacted.
  if (order.contactMethods !== undefined) {
    Object.keys(order.contactMethods).forEach(function (key) {
      contactMethodsStrings.push(key + " : " + order.contactMethods[key]);
    });
  }

  const date = month + "-" + day + "-" + year;

  // Calculates the current status of the community host if it exists.
  useEffect(() => {
    // Check to see if the user has selected a community host.
    if (
      userInfo.userType === "individual" &&
      order.deliveryFee === undefined &&
      order.communityPickup.pickupLocation !== undefined
    ) {
      // Calculates the community host's status and sets the hooks.
      CalculateCommunityLocationStatus(
        order.communityPickup,
        setCurrentStatus,
        userInfo,
      );
    } else {
      // sets the status to failed if it doesn't exist.
      setCurrentStatus(["failed to load", "failed to load", false]);
    }
  }, []);

  // Calculates the current status of the community host if it exists.
  useEffect(() => {
    // The totals for each farm
    let farmTotalsTemp = {};
    // The packaging totals per farmer
    let packageTotalsTemp = {};
    // The grand total that the user will owe.
    let grandTotalTemp = 0;
    // The total package fees the user owes.
    let packageFeeTotalTemp = 0;
    // The total delivery fees the user owes.
    let deliveryFeeTotalTemp = 0;
    // The total donation/subsidy amount from the user.
    let donationSubsidyTemp = order.donationSubsidy;
    // If the user is an individual then we will calculate the totals including the
    // packaging otherwise we don't have any packaging fees.
    if (userInfo.userType === "individual") {
      farmTotalsTemp = PriceTotals(foodList, true);
      packageTotalsTemp = PackageTotals(foodList);
    } else {
      farmTotalsTemp = PriceTotals(foodList, false);
    }

    // Sum up the grandTotal of all the farms together.
    Object.keys(farmTotalsTemp).forEach((farmTotal) => {
      grandTotalTemp += farmTotalsTemp[farmTotal];
    });

    // Cycle through the package Totals and add them all up.
    Object.keys(packageTotalsTemp).forEach((packageTotal) => {
      packageFeeTotalTemp += packageTotalsTemp[packageTotal];
    });

    // If the user is an individual.
    if (userInfo.userType === "individual") {
      // Determines the size of the grid based on the user type
      rightColumnWidth = 3;
      // If the delivery fee is 0 or greater then we will add the delivery fee.
      if (parseFloat(order.deliveryFee) >= 0) {
        deliveryFeeTotalTemp = order.deliveryFee;
        grandTotalTemp += parseFloat(deliveryFeeTotalTemp);
      }
      // Otherwise we will check if the user is picking up from a community host and that
      // the current status isn't still loading.
      else if (
        order.communityPickup.pickupLocation !== undefined &&
        currentStatus[1] !== "loading"
      ) {
        // If the community hub's partner location has a base delivery fee then calculate the delivery fee
        // from the current status and add it to the total.
        if (order.pickupLocation.baseDeliveryFee !== undefined) {
          deliveryFeeTotalTemp =
            order.pickupLocation.baseDeliveryFee / parseInt(currentStatus[1]);
          grandTotalTemp += deliveryFeeTotalTemp;
        }
      }

      // If the user's volunteer cash is greater than 0 then add it to the total.
      if (parseFloat(volunteerFeeCash) > 0) {
        grandTotalTemp += parseFloat(volunteerFeeCash);
      }

      // Add thh packaging fee if there is one.
      grandTotalTemp += packageFeeTotalTemp;

      // Add the donation or subsidy depending on which it is.
      if (order.customerContribution === "donation") {
        grandTotalTemp += donationSubsidyTemp;
      } else {
        grandTotalTemp -= donationSubsidyTemp;

        // If the grandTotal is less than 0 then we don't want the subsidy to
        // be more than the total amount so we will deduct from the total subsidy and
        // set the grandTotal to 0.
        if (grandTotalTemp < 0) {
          // The grand total will be the difference between the grand total and the subsidy
          // amount and so we can add it (subtract it since it's negative) from the subsidy amount
          // and update the subsidy amount so it doesn't go over the total owed.
          donationSubsidyTemp += grandTotalTemp;
          // We just want to make sure that the subsidy doesn't go negative
          // if there was some strange error.
          if (donationSubsidyTemp < 0) {
            donationSubsidyTemp = 0;
          }
          grandTotalTemp = 0;
        }
      }

      // Make sure that the grandTotal isn't ever negative.
      if (grandTotalTemp < 0) {
        grandTotalTemp = 0;
      }
    }

    // update all the hooks and states.
    setFarmTotals(farmTotalsTemp);
    setPackageTotals(packageTotalsTemp);
    setGrandTotal(grandTotalTemp);
    setPackageFeeTotal(packageFeeTotalTemp);
    setDeliveryFeeTotal(parseFloat(deliveryFeeTotalTemp));
    setDonationSubsidy(donationSubsidyTemp);
  }, [currentStatus[1]]);

  // This is called when the user confirms the order dialog.  This then calls
  // the function to start the orderAgain
  function handlePlaceOrder() {
    props.orderAgain(order);
  }

  return (
    <div>
      {userInfo.userType === "distributionLocation" ? (
        <>
          <h3 className="Order-Date-Distribution-Location"> {date} </h3>
          {OrderDetailsTextMyAccount(
            order,
            userInfo.organisationName,
            true,
            props.orderIndex,
            props.orderPaymentHistory,
          )}
        </>
      ) : (
        <Grid container direction="row" spacing={1}>
          <Grid item xs={rightColumnWidth}>
            <h3 className="Order-Date"> {date} </h3>
            {userInfo.userType === "individual" && (
              <>
                {communityPickup === "" ? (
                  <div className="Pickup-Location-Order-Details">
                    <strong className="Pickup-Location-Account-Header">
                      {" "}
                      Pickup Location{" "}
                    </strong>
                    <br />
                    <strong>{pickupLocation.name}</strong>
                    <br />
                    <strong>Address : </strong>
                    {pickupLocation.address}
                    <br />
                    <strong>Pickup Day : </strong>
                    {pickupLocation.pickupDay}
                    <br />
                    <strong>Pickup Time : </strong>
                    {pickupLocation.pickupTime}
                    <br />
                    <strong>Cutoff Day : </strong>
                    {pickupLocation.orderCutoff}
                    <br />
                    <strong>Invoice Number : </strong>
                    {invoiceNumber}
                    <br />

                    {/* NEEDS WORK
                    <strong>Cutoff Time : </strong>{pickupLocation.orderCutoffTime}<br/>
                  */}
                  </div>
                ) : (
                  <div className="Pickup-Location-Order-Details">
                    <strong className="Pickup-Location-Account-Header">
                      {" "}
                      Pickup Location{" "}
                    </strong>
                    <br />
                    <strong>{communityPickup.locationName}</strong>
                    <br />
                    <i>Distributed in partnership with {pickupLocation.name}</i>
                    <br />
                    <strong>Address : </strong>
                    {communityPickup.address}
                    <br />
                    <strong>Pickup Day : </strong>
                    {communityPickup.pickupDay}
                    <br />
                    <strong>Pickup Time : </strong>
                    {communityPickup.availableTimes}
                    <br />
                    <strong>Cutoff Day : </strong>
                    {pickupLocation.orderCutoff}
                    <br />

                    {/* NEEDS WORK
                    <strong>Cutoff Time : </strong>{pickupLocation.orderCutoffTime}<br/>
                  */}
                  </div>
                )}
              </>
            )}
          </Grid>
          <Grid item xs>
            <div className="Order-Food-List">
              <Orders foodList={order.foodList} pantryFoodItems={[]} />
            </div>
          </Grid>
          {userInfo.userType === "restaurant" ? (
            <Grid item xs={3}>
              <h5 className="Contact-Method-Title Food-Item-Header">
                {" "}
                Farm Contact Method{" "}
              </h5>
              {contactMethodsStrings.map((row, index) => {
                return (
                  <p key={row + index} className="Contact-Method-Item">
                    {row}
                  </p>
                );
              })}
            </Grid>
          ) : (
            <Grid item xs={3}>
              <div className="Price-Totals">
                <h5 className="Contact-Method-Title Food-Item-Header">
                  {" "}
                  Farm Price Totals{" "}
                </h5>
                {Object.keys(farmTotals).map((farmTotal, index) => (
                  <p key={farmTotal + index} className="Prices-Per-Farm">
                    {farmTotal} : ${farmTotals[farmTotal].toFixed(2)}
                    {packageTotals[farmTotal] > 0 && (
                      <span
                        style={{ verticalAlign: "super" }}
                        className="Item-Package-Fee"
                      >
                        + ${packageTotals[farmTotal].toFixed(2)} pkg fee
                      </span>
                    )}
                  </p>
                ))}
                {userInfo.userType === "individual" && (
                  <span style={{ textAlign: "right", fontSize: "12px" }}>
                    {packageFeeTotal > 0 && (
                      <p className="My-Account-Fees">
                        Total Package Fees : ${packageFeeTotal.toFixed(2)}
                      </p>
                    )}

                    {currentStatus[1] === "loading" ? (
                      <LoadingContent inline={true} />
                    ) : (
                      <>
                        {deliveryFeeTotal > 0 && (
                          <p className="My-Account-Fees">
                            Delivery Fee : ${deliveryFeeTotal.toFixed(2)}
                          </p>
                        )}
                      </>
                    )}

                    {parseFloat(volunteerFeeCash) > 0 && (
                      <p className="My-Account-Fees">
                        Participation Fee (Cash) : ${volunteerFeeCash}
                      </p>
                    )}
                    {parseFloat(volunteerFeeCredits) > 0 && (
                      <p className="My-Account-Fees">
                        Participation Fee (Credits) : {volunteerFeeCredits}
                      </p>
                    )}
                    {order.customerContribution === "donation" ? (
                      <p
                        className="Subsidy-Donation-Totals"
                        style={{ color: "green", margin: "0px" }}
                      >
                        Donation : ${donationSubsidy.toFixed(2)}
                      </p>
                    ) : (
                      <p
                        className="Subsidy-Donation-Totals"
                        style={{ color: "red" }}
                      >
                        Subsidy : ${donationSubsidy.toFixed(2)}
                      </p>
                    )}
                  </span>
                )}
                <p>
                  <b>Total </b>: ${grandTotal.toFixed(2)}
                </p>
                <Button
                  onClick={() =>
                    CreateDownloadOrderInvoice(
                      date,
                      deliveryFeeTotal,
                      order.customerContribution,
                      volunteerFeeCredits,
                      volunteerFeeCash,
                      foodList,
                      donationSubsidy,
                      userInfo,
                      invoiceNumber,
                    )
                  }
                  variant="contained"
                  color="primary"
                >
                  DOWNLOAD INVOICE
                </Button>
              </div>
            </Grid>
          )}

          {userInfo.userType === "restaurant" && (
            <Grid item xs={2}>
              <ConfirmOrderDialog
                message="Order Again"
                placeOrder={handlePlaceOrder}
                userInfo={userInfo}
              />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

// This is each individual past order by calling the Order function
export default function OrderHistory({
  order,
  orderAgain,
  orderIndex,
  userInfo,
  orderPaymentHistory,
}) {
  if (order.orderDate === "") {
    return <h3 key="noOrders"> No orders to display! </h3>;
  }
  return (
    <div key={order.orderDate + "-" + orderIndex} className="Tab-Box">
      <Order
        order={order}
        orderAgain={orderAgain}
        userInfo={userInfo}
        orderIndex={orderIndex}
        orderPaymentHistory={orderPaymentHistory}
      />
    </div>
  );
}
